@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  @font-face {
    font-family: "SVN-Gotham";
    font-weight: normal;
    src: url(./assets/fonts/SVN-Gotham-Book.otf) format("opentype");
  }
  @font-face {
    font-family: "SVN-Gotham";
    font-weight: bold;
    src: url(./assets/fonts/SVN-Gotham-Regular.otf) format("opentype");
  }
  @font-face {
    font-family: "SVN-Gotham";
    font-weight: light;
    src: url(./assets/fonts/SVN-Gotham-Light.otf) format("opentype");
  }
}

.slick-dots li button:before{
  font-size: 10px !important;
  color: #C4C4C4;
}

.slick-dots li.slick-active button:before{
  color: #EF2714 !important;
}

.aaa ul {
  @apply pl-5
}

.aaa li {
  @apply relative
}

.aaa li:before {
 @apply w-2 h-2 rounded-full bg-primary mr-3 mt-5.5 inline-block absolute;
 left: -15px;
 content: ""
}

.bbb ul {
  @apply pl-4;
  margin-top: 9px;
}

.bbb li {
  @apply relative mb-5.5 leading-1.375;
}

.bbb li:before {
 @apply bg-gray-102 mr-3 inline-block absolute;
 width: 9px;
 height: 9px;
 margin-top: 9px;
 left: -15px;
 content: ""
}

.firstLetterUppercase:first-letter {
  text-transform: uppercase;
}

.slick-slide div {
outline: none;
}

#gtx-trans { display: none !important;}
